import logo from './logo.png';
import './App.css';
import LottieAnimation from './LottieAnimation'
import ZohoForm from './ZohoForm';

function App() {
  return (
    <>
      <div className="container">
        <LottieAnimation />
       {/* <ZohoForm /> */}
          {/* <button type='button' id="zf_button_756606">Form</button> */}

      </div>
    </>
  );
}

export default App;
