// src/LottieAnimation.js
import React from 'react';
import Lottie from 'react-lottie';
import animationData from './lottie-animation.json'
import './LottieAnimation.css';

const LottieAnimation = () => {
    var defaultOptions = {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          }
    };   

  return (
    <div className="animation-container">
        <div id="lottie" className='lottie-container'>
        <Lottie options={defaultOptions} isClickToPauseDisabled={true}/>
        </div>
    </div>
  );
};

export default LottieAnimation;
